import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h2: "h2",
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "overview",
      children: _jsx(_components.a, {
        href: "#overview",
        children: "Overview"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: props.t(...props.tech.label)
      }), " is a ", _jsx(_components.a, {
        href: "/palworld/database/technology",
        children: props.t("palworld:technology", "Technology")
      }), " in ", _jsx(_components.a, {
        href: "/palworld/database",
        children: "Palworld"
      }), ". ", props.t(...props.tech.description)]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
