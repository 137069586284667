import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "clutch/src/Select/Select.js";
import { TextInput } from "clutch/src/TextInput/TextInput.js";
import type { TFunction } from "i18next";

import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import type { WikiColumn } from "@/game-palworld/components/WikiList.jsx";
import {
  getFilterByLabel,
  useWikiListFilter,
  WikiColumnName,
  WikiList,
} from "@/game-palworld/components/WikiList.jsx";
import { TECH_TYPE_TRANSLATION } from "@/game-palworld/constants/technology.mjs";
import type { Technology } from "@/game-palworld/models/model-wiki.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import type { Cell } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder";
import { useQuery } from "@/util/router-hooks.mjs";

const getTechSortValue = (
  sortOpt: TechSortOption,
  a: Technology,
  b: Technology,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, any] => [a[sortOpt.sortBy], b[sortOpt.sortBy]];

const getTechFilterByCategory =
  (categoryFilter: string) => (_t: TFunction, i: Technology) =>
    i.techType === categoryFilter;

type TechSortOption = {
  text: Translation;
  value: string;
  sortBy: keyof Technology;
};

const cols: WikiColumn[] = [
  {
    display: ["common:name", "Name"],
    primary: true,
  },
  {
    display: ["palworld:item.category", "Category"],
  },
  {
    display: ["palworld:tech.pointType", "Point Type"],
    align: "right",
  },
  {
    display: ["palworld:tech.pointCost", "Point Cost"],
    align: "right",
    isStat: true,
  },
  {
    display: ["palworld:item.levelRequirement", "Level Req."],
    align: "right",
    isStat: true,
  },
];

const getRows = (items: Technology[], t: TFunction): Cell[][] =>
  items.map(
    ({
      id,
      imageUri,
      label,
      techType,
      isAncientTech,
      pointCost,
      levelRequirement,
    }) => [
      {
        display: (
          <WikiColumnName
            imgSrc={`${appURLs.CDN_PLAIN}/${imageUri}`}
            name={t(...label)}
          />
        ),
        value: null,
        link: getWikiLink(`technology:${id}`),
      },
      {
        display: t(...TECH_TYPE_TRANSLATION[techType]),
        value: null,
      },
      {
        display: isAncientTech
          ? t("palworld:ancientTechPoints", "Ancient Tech Points")
          : t("palworld:techPoint", "Tech Points"),
        value: null,
      },
      {
        display: pointCost.toLocaleString(),
        value: pointCost,
      },
      {
        display: levelRequirement.toLocaleString(),
        value: levelRequirement,
      },
    ],
  );

const sortOptions: TechSortOption[] = [
  {
    value: "name",
    text: ["palworld:sort.name", "Name"],
    sortBy: "label",
  },
  {
    value: "category",
    text: ["palworld:sort.subCategory", "Category"],
    sortBy: "techType",
  },
  {
    value: "levelReq",
    text: ["palworld:sort.levelReq", "Level Requirement"],
    sortBy: "levelRequirement",
  },
];

const categoryOptions = [
  {
    value: "",
    text: ["palworld:category.all", "All Categories"],
    image: "",
  },
  {
    value: "building",
    text: ["palworld:category.building", "Building"],
    image: "",
  },
  {
    value: "item",
    text: ["palworld:category.item", "Item"],
    image: "",
  },
] as const;

const techTypeOptions = [
  {
    value: "",
    text: ["palworld:category.techAll", "All Point Types"],
    image: "",
  },
  {
    value: "ancient",
    text: ["palworld:category.ancientTech", "Ancient Points"],
    image: "",
  },
  {
    value: "standard",
    text: ["palworld:category.stdTech", "Technology Points"],
    image: "",
  },
] as const;

export function TechList({
  itemTech,
  buildingTech,
}: {
  itemTech: Record<string, Technology>;
  buildingTech: Record<string, Technology>;
}) {
  const { t } = useTranslation();

  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [categoryFilter, setCategoryFilter] = useQuery<string>("category", "");
  const [techTypeFilter, setTechType] = useQuery<string>("type", "");

  const items = useMemo(
    () =>
      itemTech && buildingTech
        ? [...Object.values(buildingTech), ...Object.values(itemTech)]
        : null,
    [buildingTech, itemTech],
  );

  const filters = useMemo(() => {
    const filters = [];
    if (nameFilter) filters.push(getFilterByLabel(nameFilter));
    if (categoryFilter) filters.push(getTechFilterByCategory(categoryFilter));
    if (techTypeFilter)
      filters.push((_t, tech: Technology) =>
        techTypeFilter === "ancient" ? tech.isAncientTech : !tech.isAncientTech,
      );

    return filters.length ? filters : null;
  }, [categoryFilter, nameFilter, techTypeFilter]);

  const {
    allCols,
    rows,
    sortOption: [sortMode, setSortMode],
    sortToggle: [sortOrder, setSortOrder],
  } = useWikiListFilter({
    t,
    cols,
    getSortValue: getTechSortValue,
    filters,
    defaultSortKey: "levelReq",
    getRows,
    items,
    sortOptions,
  });

  return (
    <WikiList>
      <div className="search-container">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setNameFilter(e.target.value)}
          defaultValue={nameFilter}
          Icon={SearchIcon}
        />
        <Select
          options={categoryOptions}
          selected={categoryFilter || ""}
          onChange={(v) => setCategoryFilter(v || undefined)}
        />
        <Select
          options={techTypeOptions}
          selected={techTypeFilter || ""}
          onChange={(v) => setTechType(v || undefined)}
        />
        <div className="sort-container">
          <Select
            options={sortOptions}
            selected={sortMode || ""}
            onChange={(v) => setSortMode(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      <DataTable
        className="wiki-table"
        sortable={false}
        loadingRows={25}
        cols={allCols}
        rows={rows}
      />
    </WikiList>
  );
}
